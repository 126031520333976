import React from "react";
import { graphql } from "gatsby";
import { Link } from "@intractive/gatsby-plugin-react-intl";
import * as PropTypes from "prop-types";
import Helmet from "react-helmet";
import Layout from '../../Layout'

const style = require("../index.module.css");

const propTypes = {
  data: PropTypes.object.isRequired,
}

class SubpageMensenTemplate extends React.Component {
  render() {
    const page = this.props.data.pageData
    const parent = this.props.data.parentData

    const {
      title,
      metaDescription,
      pageTitle,
      text,
    } = page

    return (
      <Layout>
        <Helmet>
          <title>{title} · STIP</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <div className="row main-content">
          <div className="large-3 medium-3 columns sidebar hide-for-small">
            <div className="nav-sub">
              <ul>
                  <li><Link to={`/${parent.slug}/`}>{parent.title}</Link>
                  <ul>
                    {parent.subpages && parent.subpages.map(function(subpage, index){
                      return (
                        <li key={index}><Link to={`/${parent.slug}/${subpage.slug}/`} activeClassName={style.active}>{subpage.pageTitle}</Link></li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="large-9 medium-9 columns">
            <section>
              <h1>{pageTitle ? pageTitle : title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: text.childMarkdownRemark.html,
                }}
              />
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

SubpageMensenTemplate.propTypes = propTypes

export default SubpageMensenTemplate

export const subpageMensenQuery = graphql`
  query($slug: String! $parent: String!, $node_locale: String) {
    pageData: contentfulSubpage(slug: { eq: $slug }, node_locale: {eq:$node_locale}) {
      title
      metaDescription
      pageTitle
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    parentData: contentfulPagePersonen(slug: { eq: $parent }, node_locale: {eq: $node_locale}) {
      title
      slug
      subpages {
        slug
        title
        pageTitle
      }
    }
  }
`
